import { SubscriptionCardProps } from '@/components/Cards/SubscriptionCard'

/*
 * Action Responses
 */
export type ActionResponse<Type = any> = {
  ok: boolean
  error?: any
  payload?: Promise<Type>
}

export type SignInActionResponse = {
  ok: boolean
  error?: any
  isFirstLogin: boolean
}

export type PaginationData<Type> = {
  count: number
  next: string | null
  previous: string | null
  results: Type[]
}

/*
 * Users
 */
export type User = {
  id: number
  email: string
  first_name: string
  last_name: string
  is_staff: boolean
  is_active: boolean
  date_joined: string
  is_first_login: boolean
  is_email_verified: boolean
}

export type UserFeedback = {
  id: string
  user: number
  score: number
  question: string
  answer: string
  created_at: string
}

export type UserProfile = {
  user: number
  company: string | null
  job_title: string | null
}

export type UserProfileUpdateParams = {
  company?: string
  jobTitle?: string
}
/*
 * Workspace Query
 */
export type WorkspaceQueryJobStats = {
  worksheet_query_id: string
  total_jobs: number
  status_counts: {
    [key: string]: number
  }
  agent_status_counts: {
    [agentId: string]: {
      agent_name: string
      status_counts: {
        [key: string]: number
      }
    }
  }
}

export type QueryHelperResponse = {
  prompt: string
  is_valid: boolean
  reasoning: string
  query: string
}

/*
 * Organizations
 */
export type Organization = {
  id: string
  name: string
  creator: User
  created_at: string
  updated_at: string
  show_job_cost: boolean
}

export type OrganizationUserRole = 'admin' | 'member'

export type OrganizationUser = {
  id: string
  user: User
  organization: Organization
  role: OrganizationUserRole
  created_at: string
  updated_at: string
}

export type OrganizationAPIKey = {
  uuid: string
  name: string
  created: string
  last_used: string
  preview_key: string
}

export type OrganizationAPIKeyWithSecretKey = OrganizationAPIKey & {
  secret_key: string
}

/*
 * Agents
 */
export type AgentInputDefinition = {
  key: string
  data_type: string
  description: string
  example?: string
  accepts_multiple_files?: boolean | null
}

export type AgentInput = {
  key: string
  data_type: string
  description?: string
  value: string | File | File[]
  file_name: string | null
  accepts_multiple_files?: boolean | null
  example?: string
}

export type AgentDatum = {
  key: string
  data_type: string
  description?: string
  value: string
}

const agentInputDefinitionExample: AgentInputDefinition = {
  key: '',
  data_type: '',
  description: '',
}

export const AgentInputDefinitionKeys = Object.keys(agentInputDefinitionExample)

export type AgentPrompt = {
  key: string
  prompt: string
  description: string
  base64_image: string
}

export type BaseAgent = {
  disable_cache: boolean
  cache_failed_jobs: boolean
}

export type Agent = {
  id: string
  agent_id: string
  name: string
  version_name: string
  creator?: User
  created_at: string
  description: string
  input_definitions: AgentInputDefinition[]
  engine_class_id: string
  engine_name: string
  engine_config: {
    [key: string]: string
  }
  readonly?: boolean
  job_count: number
  most_recent_job: string
  organization?: string
  supports_evaluation?: boolean
  base_agent: BaseAgent
}

export type AgentCreationParams = {
  name: string
  engine_name: string // TODO: CHECK CAN GET RID OF THIS?
  description: string
  input_definitions: AgentInputDefinition[]
  engine_class_id: string
  engine_config: {
    [key: string]: string
  }
  organization?: string
  isDirty?: boolean // TODO: CHECK CAN GET RID OF THIS?
}

export const EmptyAgentCreationParams: AgentCreationParams = {
  name: '',
  engine_name: '',
  description: '',
  input_definitions: [
    {
      key: '',
      data_type: '',
      description: '',
    },
  ],
  engine_class_id: '',
  engine_config: {},
}

export type PDFParserFeatures = {
  table: {
    enabled: boolean
  }
  chart: {
    enabled: boolean
    with_data: boolean
    prompt?: string
  }
  logo: {
    enabled: boolean
  }
  markdown: {
    enabled: boolean
  }
  photo: {
    enabled: boolean
    prompt?: string
  }
}

export const DEFAULT_PDF_PARSER_FEATURES: PDFParserFeatures = {
  markdown: {
    enabled: true,
  },
  table: {
    enabled: false,
  },
  chart: {
    enabled: false,
    with_data: true,
  },
  logo: {
    enabled: false,
  },
  photo: {
    enabled: false,
  },
}

export type StatusEvent = {
  timestamp: string
  status_code: number
  error_message: string
  error_details: {
    [key: string]: any
  }
  count?: number
}

export type AgentJob = {
  id: string
  agent: string
  agent_version_name?: string
  workflow: number
  status_events: StatusEvent[]
  created_at: string
  engine_class_id: string
  engine_config: {
    [key: string]: string
  }
  blob?: {
    agent_job_id: string
    inputs?: AgentInput[]
    outputs: AgentDatum[]
    corrected_outputs: AgentDatum[]
    logs: EngineLog[]
  }
  task_id: string
  input_tokens?: number
  output_tokens?: number
  cost: number
  job_inputs?: AgentInput[]
  last_updated_at: string
  is_eval_job: boolean
}

export type EngineLog = {
  level: string
  message: string
  timestamp: number
  image: string
}

export type TestAgentJobResult = {
  status_events: StatusEvent[]
  outputs: AgentDatum[]
  task_id: string
  cost: number
  logs: EngineLog[]
}

/*
 * Datasets
 */
export type Dataset = {
  id: string
  name: string
  creator?: User
  created_at: string
  organization?: string
}

export type DatasetCreationParams = {
  name: string
  organization?: string
}

export type RoeFile = {
  id: string
  dataset: string
  name: string
  size: number
  content_type: string
  blob_id: string
  creator?: User
  created_at: string
}

export type RoeFileDetailed = {
  id: string
  dataset: string
  name: string
  size: number
  content_type: string
  blob_id: string
  creator: User
  created_at: string
  metadata: {
    [key: string]: string
  }
  meta: {
    [key: string]: any
  }
}

/*
 * Data Resources
 */

export type DataResourceType = {
  id: string
  name: string
  fields: DataResourceTypeField[]
  category?: string
  integration?: string
}

export type DataResourceTypeField = {
  id: string
  label: string
  required: boolean
  placeholder: string | null
  help_text: string | undefined
  name: string
  value_max_length: number | null
  is_text_area: boolean
  field_type: string
}

export type DataResource = {
  id: string
  name: string
  data_resource_type: DataResourceType | any
  created_at: string
  settings: {
    id: string
    data_resource_type_field: DataResourceTypeField
    field_value: string
  }[]
}

export type DataResourceForm = {
  data_resource_type_id: string
  settings: {
    data_resource_type_field: string // this is data_resource_type_field.id
    field_value: string
  }[]
}

export type ResourceFormCreate = {
  name: string
  data_resource_type: string
  settings: {
    data_resource_type_field: string
    field_value: string
  }[]
  // link_token: string;
}

export type SnowflakeShema = {
  result: string[]
}

export type BucketFile = {
  content_type: string
  name: string
  size: number
}

export type BucketFiles = {
  files: BucketFile[]
}

export type UploadFiles = {
  files: string[]
  table_name: string
}

export type SetAccountToken = {
  public_token: string
  data_resource_id?: string
}
/*
 * Training
 */
export type MLModel = {
  model_id: string
  creator: User
  created_at: string
  vendor: string
  status: string
  model_type: string
}

export type AIEngine = {
  class_id: string
  display_name: string
  description: string
  input_datum_definitions: InputDatumDefinition[]
  output_datum_definitions: {
    key: string
    description: string
    data_types: string[]
  }[]
}

export type InputDatumDefinition = {
  key: string
  data_types: string[]
  display_name?: string
  description?: string
  is_required?: boolean
  allowed_values?: string[] | null
  accepts_multiple_files?: boolean | null
  default_value?: any
  example?: string
  is_numeric?: boolean
  is_integer?: boolean
  min_value?: number
  max_value?: number
  supported_models?: string[]
}

/*
 * Roe Database
 */
export type TableSchema = {
  name: string
  columns: {
    name: string
    type: string
  }[]
}

export type QueryResult = {
  query_id: string
  query: string
  summary: {
    [key: string]: any
  }
  row_count: number
  column_names: string[]
  column_types: string[]
  result_rows: string[][]
  start_timestamp: number
  end_timestamp: number
}

export type QueryStatus = {
  status: string
  error: string
}

export type AsyncQueryResult = {
  query_task_id: string
  results: QueryResult[]
  error: string
}

export type TabType = 'table' | 'query'
export type Tab = {
  type: TabType
  id: string
  name?: string
  code?: string
  queries?: Query[]
  query_id?: number // to be deprecated
  isDirty?: boolean
}

export type Query = {
  id: string
  query: string
  query_status?: string
  error?: string
  startTime?: number
  endTime?: number
  query_run_id?: string // to be deprecated
  query_run_status?: string // to be deprecated
}

export type WorkspaceState = {
  tabs: Tab[]
  selectedTabIndex: number
  queries?: {
    // to be deprecated
    [key: number]: Query
  }
  lastUpdatedAt: number
  isDirty: boolean
}

/*
 * Search Index
 */

export type SearchIndexEmbedderConfig = {
  version: string
  pdf: {
    text_embedder_name: string
    image_embedder_name: string
  }
  text: {
    embedder_name: string
  }
  image: {
    embedder_name: string
  }
  audio: {
    embedder_name: string
  }
  video: {
    embedder_name: string
  }
}

export type SearchIndex = {
  id: string
  display_name: string | null
  name: string
  database: {
    id: string
    name: string
    created_at: string
    owner: number
  }
  table_name: string
  id_column_name: string
  column_names: string[]
  index_task_id: string | null
  created_at: string
}

export type SearchIndexQueryResult = {
  column_names: string[]
  column_types: string[]
  result_rows: string[][]
}

export type JSONSchama = {
  $schema?: string
  type:
    | 'null'
    | 'boolean'
    | 'object'
    | 'array'
    | 'number'
    | 'string'
    | 'date'
    | 'money'
    | 'enum'
  title?: string
  description?: string
  properties?: {
    [key: string]: JSONSchama
  }
  items?: JSONSchama
  enum?: string[]
}

export const DEFAULT_OUTPUT_JSON_SCHEMA: JSONSchama = {
  type: 'null',
}

/*
 * Billing
 */
export const BASIC_PLAN = 'basic'
export const PRO_PLAN = 'pro'
export const ENTERPRISE_PLAN = 'enterprise'
export type SubscriptionPlanName =
  | typeof BASIC_PLAN
  | typeof PRO_PLAN
  | typeof ENTERPRISE_PLAN
export const BASIC_TIER1 = '500'
export const BASIC_TIER2 = '1000'
export const BASIC_TIER3 = '2000'
export const BASIC_TIER4 = '4000'
export const PRO_TIER1 = '800'
export const PRO_TIER2 = '1550'
export const PRO_TIER3 = '3000'
export const PRO_TIER4 = '5800'
export type SubscriptionPlanTier =
  | typeof BASIC_TIER1
  | typeof BASIC_TIER2
  | typeof BASIC_TIER3
  | typeof BASIC_TIER4
  | typeof PRO_TIER1
  | typeof PRO_TIER2
  | typeof PRO_TIER3
  | typeof PRO_TIER4
export type PDFSubscriptionPlan = 'free' | 'standard' | 'enterprise' | 'pdf'
const PLANS_JOB_LIMITS = {
  FREE_TRIAL: 200,
  BASIC_PLAN: 25,
  PRO_PLAN: 200,
  ENTERPRISE_PLAN: 500, // default value
}
const PLANS_REQUEST_RATE_LIMITS = {
  BASIC_PLAN: '30 requests/min',
  PRO_PLAN: '60 requests/min',
  ENTERPRISE_PLAN: '1000 requests/min',
}

// Add Ons
export const ADDON_VOLANSDB = 'volansdb'

// Features
export const AGENTS_FEATURE = 'agents'
export const ORGANIZATION_INVITE_FEATURE = 'org_invite'
export const EVALUATIONS_FEATURE = 'evaluations'
export const FINETUNING_FEATURE = 'finetuning'
export const SSOLOGIN_FEATURE = 'sso_login'

export const ALL_GATED_FEATURES = [
  ADDON_VOLANSDB,
  ORGANIZATION_INVITE_FEATURE,
  EVALUATIONS_FEATURE,
]
export const subscriptionOptions: SubscriptionCardProps[] = [
  // {
  //   planName: BASIC_PLAN,
  //   pricingOptions: [
  //     {
  //       value: BASIC_TIER1,
  //       cost: BASIC_TIER1,
  //       displayText: `500 credits`,
  //     },
  //     {
  //       value: BASIC_TIER2,
  //       cost: BASIC_TIER2,
  //       displayText: `1000 credits`,
  //     },
  //     {
  //       value: BASIC_TIER3,
  //       cost: BASIC_TIER3,
  //       displayText: `2000 credits`,
  //     },
  //     {
  //       value: BASIC_TIER4,
  //       cost: BASIC_TIER4,
  //       displayText: `4000 credits`,
  //     },
  //   ],
  //   features: [
  //     `Up to ${PLANS_JOB_LIMITS.BASIC_PLAN} simultaneous jobs and ${PLANS_REQUEST_RATE_LIMITS.BASIC_PLAN}`,
  //     'ROE AI Agent Hub for Audio, Image, Video, and Text Data',
  //     'Web Data Agent with Built-in Crawler',
  //     'Extreme Precision Structured Data Extraction',
  //     'Extreme Precision RAG-friendly Markdown Conversion',
  //     'Sync and Async REST API',
  //   ],
  // },
  // {
  //   planName: PRO_PLAN,
  //   pricingOptions: [
  //     {
  //       value: PRO_TIER1,
  //       cost: PRO_TIER1,
  //       displayText: `1000 credits`,
  //     },
  //     {
  //       value: PRO_TIER2,
  //       cost: PRO_TIER2,
  //       displayText: `2000 credits`,
  //     },
  //     {
  //       value: PRO_TIER3,
  //       cost: PRO_TIER3,
  //       displayText: `4000 credits`,
  //     },
  //     {
  //       value: PRO_TIER4,
  //       cost: PRO_TIER4,
  //       displayText: `8000 credits`,
  //     },
  //   ],
  //   features: [
  //     `All of the Basic Plan features`,
  //     `Up to ${PLANS_JOB_LIMITS.PRO_PLAN} simultaneous jobs and ${PLANS_REQUEST_RATE_LIMITS.PRO_PLAN}`,
  //     'Agent and Job Evaluations',
  //     'PDF Source Tracing',
  //     'Organization Sharing',
  //     '24-hour support response time',
  //   ],
  // },
  {
    planName: ENTERPRISE_PLAN,
    pricingOptions: [],
    features: [
      'Uncapped Credits',
      'Distributed SQL Database for Efficient High-Throughput Agent Workflows at Scale',
      'Agents with Extremely Precice Unstructured Data Extraction on Audio, Image, Video, and Text Data',
      'Web Data Agent with Built-in Crawler',
      'AI Agent Evaluations',
      'PDF Source Tracing',
      'Organization Sharing',
      `At Minimum ${PLANS_JOB_LIMITS.ENTERPRISE_PLAN} Simultaneous Jobs and ${PLANS_REQUEST_RATE_LIMITS.ENTERPRISE_PLAN}`,
      'Your Own Dedicated Non-blocking Queue for Scheduling Agent Jobs',
      'Provisioned Capacity',
      'SSO Login',
      'Sync and Async API Support',
      '1-hour, Dedicated Support Response Time',
      'Single Tenant VPC On-premises Deployment',
    ],
  },
]
export type CurrentPlan = {
  plan: SubscriptionPlanName
  price_tier: SubscriptionPlanTier
  add_ons: string[]
  end_at: number | null
  in_trial: boolean
  trial_end_at: number | null
}
export type FeatureAccessType = { [key: string]: boolean }
export type HasUsedTrialResponse = {
  has_used_trial: boolean
}

/*
 * Chat Messages Types
 */

type ChatUIMessageRoles = 'user' | 'assistant' | 'CHATBOT' | 'chatbot'

export type ParsedMessage = {
  role: ChatUIMessageRoles
  content?: string
  message?: string
}

export type ParsedJSON = {
  messages?: ParsedMessage[]
} | null

/*
 * Evaluation Types
 */

export type EvaluationItem = {
  id: string
  inputs: { [key: string]: any }
  output: { [key: string]: any }
  is_compatible: boolean
  from_which_job: string | null
  [key: string]:
    | { score: number; result_id: string }
    | string
    | boolean
    | null
    | object
}

export type EvaluationConfigSchema = {
  method: string
  [key: string]: EvaluationConfigSchema | string | boolean | undefined
  case_insensitive?: boolean
  llm_instructions?: string
  items?: EvaluationConfigSchema
}

export type EvaluationMetricsConfig = {
  id: string
  name: string
  eval_config: EvaluationConfigSchema | null
  is_valid: boolean
}

export type EvaluationComparisonObject = {
  ground_truth: { [key: string]: any } | null
  eval_output: { [key: string]: any } | null
  score: number | null
}
